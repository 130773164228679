import qs from 'qs';
import { setSubmitting } from 'src/redux/modules/webpage/loading';

import { API_METHOD, authApiClient } from '../../api';
export const LOAD_PRODUCT_OPTIONS = 'LOAD_PRODUCT_OPTIONS';
export const LOAD_ENTIRE_PRODUCT_OPTIONS = 'LOAD_ENTIRE_PRODUCT_OPTIONS';
export const FILTER_PRODUCT_OPTIONS = 'FILTER_PRODUCT_OPTIONS';

export const loadProductOptions = productOptions => ({
  type: LOAD_PRODUCT_OPTIONS,
  productOptions,
});

export const loadEntireProductOptions = entireProductOptions => ({
  type: LOAD_ENTIRE_PRODUCT_OPTIONS,
  entireProductOptions,
});

export const handleLoadProductOptions = productId => {
  return async dispatch => {
    try {
      const response = await authApiClient(API_METHOD.GET, `product/option?product_id=${productId}`, undefined);
      dispatch(loadProductOptions(response.data.result));
    } catch (e) {
      alert(e.message);
    }
  };
};

export const handleLoadEntireProductOptions = query => {
  return async dispatch => {
    try {
      dispatch(setSubmitting(true));
      const response = await authApiClient(API_METHOD.GET, `product/option?${qs.stringify(query)}`, undefined);
      dispatch(loadEntireProductOptions(response.data.result));
    } catch (e) {
      alert(e.message);
    } finally {
      dispatch(setSubmitting(false));
    }
  };
};

export const duplicateProductOptionForEntire = (productOptionId, query) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    const response = await authApiClient(API_METHOD.POST, 'product/option/duplicate', {
      productOptionId,
    });
    if (response.data.code === 1) {
      dispatch(handleLoadEntireProductOptions(query));
    }
  } catch (error) {
    alert(error);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleProductOptionStatusUpdateInEntireList = (productOption, query) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `product/option/${productOption.id}`, productOption);
    dispatch(handleLoadEntireProductOptions(query));
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleProductOptionStatusUpdate = (productOption, productId) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `product/option/${productOption.id}`, productOption);
    dispatch(handleLoadProductOptions(productId));
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

const productOptions = (state = [], action) => {
  switch (action.type) {
    case LOAD_PRODUCT_OPTIONS: {
      action.productOptions.rows = action.productOptions.rows?.map(item => ({
        ...item,
        component_arr:
          (item.component_arr &&
            JSON.parse(item.component_arr).map(
              // display name in multiselect, make product_id string to solve server error
              item => ({
                ...item,
                product_id: String(item.product_id),
                label: item.real_name,
                value: item.real_name,
              })
            )) ||
          [],
        static_price: item?.static_price || '',
      }));
      return action.productOptions;
    }
    case LOAD_ENTIRE_PRODUCT_OPTIONS: {
      action.entireProductOptions.rows = action.entireProductOptions.rows
        ?.map(item => ({
          ...item,
          product_name: item.product?.name || '',
          maker_name: item.product?.maker?.name || '',
          static_price: item?.static_price || '',
          sool_category_name: item.product?.sool?.sool_category?.name || '',
        }))
        .sort((a, b) => b.id - a.id);
      return action.entireProductOptions;
    }

    default:
      return state;
  }
};

export default productOptions;
