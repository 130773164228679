import { setSubmitting } from 'src/redux/modules/webpage/loading';
import { productOptionValues } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient } from '../../api';
import { handleLoadProductOptions, loadProductOptions } from './productOptions';

export const LOAD_PRODUCT_OPTION = 'LOAD_PRODUCT_OPTION';
export const SAVE_PRODUCT_OPTION = 'SAVE_PRODUCT_OPTION';
export const UPDATE_PRODUCT_OPTION = 'UPDATE_PRODUCT_OPTION';

export const saveProductOption = productOption => ({
  type: SAVE_PRODUCT_OPTION,
  productOption,
});

export const loadProductOption = productOption => ({
  type: LOAD_PRODUCT_OPTION,
  productOption,
});

export const updateProductOption = productOption => ({
  type: UPDATE_PRODUCT_OPTION,
  productOption,
});

export const handleSaveProductOption = (productOption, addToast) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.POST, 'product/option', productOption);
    addToast();
    await authApiClient(API_METHOD.GET, `product/option?product_id=${productOption.product_id}`, undefined);
    dispatch(handleLoadProductOptions(productOption.product_id));
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleUpdateProductOption = (productOption, productOptions, addToast) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    await authApiClient(API_METHOD.PATCH, `product/option/${productOption.id}`, productOption);
    if (productOptions == undefined) {
      addToast();
      await authApiClient(API_METHOD.GET, `product/option?product_id=${productOption.product_id}`, undefined);
      dispatch(handleLoadProductOptions(productOption.product_id));
    }
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const duplicateProductOption = productOptionId => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    const response = await authApiClient(API_METHOD.POST, 'product/option/duplicate', {
      productOptionId,
    });
    if (response.data.code === 1) {
      dispatch(handleLoadProductOptions(response.data.result.product_id));
    }
  } catch (error) {
    alert(error);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleDeleteProductOption = productOption => async dispatch => {
  try {
    await authApiClient(API_METHOD.DELETE, `product/option/${productOption.id}`, undefined);
    const response = await authApiClient(
      API_METHOD.GET,
      `product/option?product_id=${productOption.product_id}`,
      undefined
    );
    dispatch(loadProductOptions(response.data.result.rows));
  } catch (e) {
    alert(e.message);
  }
};

export const handleLoadProductOption = () => async dispatch => {
  try {
    dispatch(loadProductOption(productOptionValues));
  } catch (e) {
    alert(e.message);
  }
};

const productOption = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PRODUCT_OPTION:
      return action.productOption;
    default:
      return state;
  }
};

export default productOption;
